<template>
    <NuxtLink ref="accountMenu" @click="menuToggle" :to="isSignedIn ? '' : '/login'"
        class="relative shadow-light rounded-full py-2 px-5 cursor-pointer transition-all duration-500 text-black dark:text-white
            hover:text-black dark:hover:text-white dark:hover:bg-slate-600 hover:no-underline hover:shadow-md focus:text-black dark:focus:text-white focus:no-underline
            group flex gap-2"
            v-bind:class="{
                'bg-gray-100 dark:bg-slate-700': !isMenuOpen,
                'bg-blue-100 dark:bg-slate-700': isMenuOpen,
            }">
            <UserProfilePicture :id="user?.id" />
            <div class="hidden md:flex flex-wrap">
                <div class="userdata basis-full text-base font-semibold min-w-40" v-bind:class="{'bg-blue-100 dark:bg-gray-600 rounded w-full h-4 mb-2 animate-pulse text-transparent' : pending }">{{ isSignedIn ? (pending?' ':fullname) : 'Mein Konto'}}</div>
                <div class="userdata basis-full truncate text-clip" v-bind:class="{'bg-blue-100 dark:bg-gray-600 rounded w-1/2 h-4 animate-pulse' : pending }">
                    <template v-if="pending"></template>
                    <template v-else-if="isSignedIn">
                        <template v-if="isProducer">
                            {{ (business?.data?.length > 0 ? business?.data?.[0]?.attributes?.name : user.tmpBusiness) }}
                        </template>
                        <template v-else>
                            {{ user.organisation?.name ?? (isAdmin ? 'Administrator' : '') }}
                        </template>
                    </template>
                    <template v-else>Anmelden</template>
                </div>
            </div>
            <div class="m-auto"><ElementsIcon icon="carretDown" class="transition-transform duration-300 ease-over-out" v-bind:class="{ 'rotate-180' : isMenuOpen}" /></div>
        <TransitionsGrow v-if="isSignedIn">
            <div v-if="isMenuOpen" class="min-w-full rounded-2xl drop-shadow-xl border transition-all absolute right-0 top-full mt-1 bg-white dark:bg-slate-800 dark:border-slate-800 z-50">
                <div class="rounded-2xl overflow-hidden z-50 relative">
                    <NuxtLink :to="{ name: 'user-id-slug', params: { id: 'me' }}">
                        <ElementsButton showAs="full-left" color="white" icon="user" isSquare grayIcon>Mein Konto</ElementsButton>
                    </NuxtLink>
                    <NuxtLink v-if="user.organisation?.name" :to="{ name: 'organization-slug', params: { slug: user.organisation?.id }, }">
                        <ElementsButton showAs="full-left" color="white" icon="institute" isSquare grayIcon>{{ user.organisation?.name }}</ElementsButton>
                    </NuxtLink>
                    <template v-if="!user.organisation?.name && business?.data?.length > 0">
                        <NuxtLink v-for="b in business?.data" :key="b.id" :to="{ name: 'business-id-slug', params: { id: b.id, slug: '' }}">
                            <ElementsButton showAs="full-left" color="white" icon="business" isSquare grayIcon>{{ b.attributes.name }}</ElementsButton>
                        </NuxtLink>
                    </template>
                    <ElementsButton @click="logoutClicked" showAs="full-left" color="white" icon="logout" isSquare grayIcon>Abmelden</ElementsButton>
                </div>
                <div class="absolute w-4 h-4 bg-white dark:bg-slate-800 -top-2 right-16 md:right-1/2 rotate-45 translate-x-2 mt-px -z-1 border dark:border-slate-800"></div>
            </div>
        </TransitionsGrow>
    </NuxtLink>
</template>
<script setup>
import { onClickOutside } from '@vueuse/core'
const { find, create } = useStrapi4()
const { logout } = useStrapiAuth()

const user = useStrapiUser()
const router = useRouter()
const route = useRoute()

const isProducer = useIsProducer()
const isAdmin = useIsAdmin()

const menuToggle = () => {
    if(route.path == '/login'){
        isMenuOpen.value = false
        return useGoBack()
    }
    isMenuOpen.value = !isMenuOpen.value
}

const hasProfilePic = ref(false)

const { data: business, pending } = useLazyAsyncData(
    'businesses-user-me',
    () => useIfIsSignedIn(() => find('businesses', {
        filters: {
            users: { id: { $in: user.value.id } }
        }
    }),
    [])
)

const isSignedIn = computed(() => user.value )
const fullname = computed(() => isSignedIn ? user.value.firstname + '  ' + user.value.lastname : '' )
const logoutClicked = async () => {
    try {
        await create('auth/logout', { data: null, })
    } catch(e) {
        console.log(e);
        useBugsnag().notify(e)
    }
    logout()
    window.location.href = '/'
}


const isMenuOpen = ref(false)
const accountMenu = ref(null)

onClickOutside(accountMenu, (event) => isMenuOpen.value = false)
</script>